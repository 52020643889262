import { TailSpin } from "react-loader-spinner";
import Style_obj from "../style_obj";
// Style_obj

const LoaderComp = (props) => {
    const styleObj=Style_obj()
    return (
        <TailSpin
            height={props.height || "35px"}
            width={props.width || "80px"}
            color={"white"}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
        />
    );
};  
export default LoaderComp;