import React, { useEffect, useRef, useState } from "react";
import Page_titles from "../../components/Page_titles";
import Stats from "../../components/Stats";
import Chart1 from "../../components/Chart1";
import {
  all_plans_service,
  update_meeting_link_service,
} from "../../services/plan";
import Msg_display from "../../components/Msg_display";

const App = (props) => {
  const [msg,setMsg] =useState({status:"",msg:""})
  const link_ref=useRef();
  const selected_plan_ref=useRef();
  const message_ref=useRef();

  const [plans_data, setplans_data] = useState([]);

  const send_handler = async (e) => {
    e.preventDefault();
    setMsg({status:null,msg:"submitted successfully"})
      const link_ref_value=link_ref.current.value;
      const selected_plan_ref_value=selected_plan_ref.current.value;
      const message_ref_value=message_ref.current.value;

    const resp = await update_meeting_link_service(link_ref_value,selected_plan_ref_value,message_ref_value);
    if (resp.status >= 200) {
      // setplans_data(resp.data.data);
      setMsg({status:true,msg:"submitted successfully"})
    }
    else{
      setMsg({status:false,msg:"try again"})
    }
    // alert("ok");
  };
  useEffect(() => {
    const handler = async () => {
      const resp = await all_plans_service();
   
      if (resp.status >= 200) {
        setplans_data(resp.data.data);
        // setMsg({status:true,msg:"submitted successfully"})
      }
      else{
        // setMsg({status:false,msg:"try again"})
      }

    };
    handler();
  }, []);


  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Meeting Link Sender</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label">meeting link</label>
                        <input
                        ref={link_ref}
                          type="text"
                          className="form-control"
                          placeholder=" meeting link"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Message</label>
                        <input
                        ref={message_ref}
                          type="text"
                          className="form-control"
                          placeholder=" meeting  merssage"
                        />
                      </div>
                    </div>

                    <div className="mb-3 col-md-4">
                      <label className="form-label">chose plan</label>
                      <select
                      ref={selected_plan_ref}
                        id="inputState"
                        className="default-select form-control wide"
                      >
                        <option selected="">Choose...</option>
                        {plans_data.map((d) => {
                          return <option value={d._id}>{d.plan_name}</option>;
                        })}
                      </select>
                      <br/>
                      <div>
                        <Msg_display status={msg.status} msg={msg.msg} />
                      </div>
                    </div>
                    <button onClick={send_handler} className="btn btn-primary">
                      send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
