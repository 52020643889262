

import React from 'react'

function Style_obj() {
    const css_obj={
        background_color1:"#265AC8",
        background_color_field:"#F3FAFF",//for bg field color
        background_color_button:"#34a8fa",//for action buttons
        background_color4:"",
        background_color5:"",
        background_color6:"",
        }
  return css_obj
   
  
}

export default Style_obj


