import React, { useState } from 'react'

function Show_hide({btn_name,children}) {
    const  [state, setstate] = useState(false);
  return (
    <>
        {state==true?<>
        <button onClick={()=>{setstate(!state)}} className='btn btn-primary'>{btn_name} X</button>
        <div>
            <br />
        {children}
        </div>
            
             
             </>
        :
        <button onClick={()=>{setstate(!state)}} className='btn btn-primary'> {btn_name}</button>
    }

    </>
  )
}

export default Show_hide