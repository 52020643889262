import React, { useEffect, useRef, useState } from "react";
import Page_titles from "../../components/Page_titles";
import Stats from "../../components/Stats";
import Chart1 from "../../components/Chart1";
import {
  all_plans_service,
  update_meeting_link_service,
} from "../../services/plan";
import { add_coupon_code, add_coupon_code_service, all_coupons_service, delete_coupon_service } from "../../services/coupon";
import LoaderComp from "../../components/Loader";

const Create_coupon = ({setState_update}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resp_msg, setResp_msg] = useState("");
  const [api_resp_msg, setApi_resp_msg] = useState("");
  const [show_create_form, setShow_create_form] = useState(false);
  const active_ref = useRef();
  const name_ref = useRef();
  const coupon_code_ref = useRef();
  const details_ref = useRef();
  const expire_date_ref = useRef();
  const discount_percentage_ref=useRef()
  const send_handler = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(false);

      const active_value = active_ref.current.value;
      const name_value = name_ref.current.value;
      const coupon_code_value = coupon_code_ref.current.value;
      const details_value = details_ref.current.value;
      const expire_date_value = expire_date_ref.current.value;
      const discount_percentage_value=discount_percentage_ref.current.value
   

      setIsLoading(true);
      const resp = await add_coupon_code_service({
        active_value,
        name_value,
        coupon_code_value,
        details_value,
        expire_date_value,
        discount_percentage_value
      });

      if (resp.status === 201) {
        setTimeout(() => {
          setIsLoading(false);
          setResp_msg(1);
          setApi_resp_msg(resp?.data?.msg);
          setState_update(e=>e+1)
        }, 1000);
      } else if (resp.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          setResp_msg(2);
          setApi_resp_msg(resp?.data?.msg);
        }, 1000);
      } else {
        setTimeout(() => {
          setIsLoading(false);
          setResp_msg(false);
          setApi_resp_msg(resp?.data?.msg);
        }, 1000);
      }
      // console.log(resp, "resp");
    } catch (error) {
      alert("not working");
      console.log(error);
    }
  };

  return (
    <>
      <div className="col-xl-6 col-lg-12">
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            setShow_create_form(!show_create_form);
          }}
        >
          {show_create_form ? "Close" : "Create Coupon"}
        </button>
        {show_create_form && (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create Coupon</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Coupon Name</label>
                      <input
                        ref={name_ref}
                        type="text"
                        className="form-control"
                        placeholder="Coupon name"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Coupon code</label>
                      <input
                        ref={coupon_code_ref}
                        type="text"
                        className="form-control"
                        placeholder="Enter code for coupon"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Discount Percentage</label>
                      <input
                        ref={discount_percentage_ref}
                        type="Number"
                        className="form-control"
                        placeholder="Discount Percentage"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Details</label>
                      <input
                        ref={details_ref}
                        type="text"
                        className="form-control"
                        placeholder="Details"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Coupon expire date</label>
                      <input
                        ref={expire_date_ref}
                        type="date"
                        className="form-control"
                        placeholder="Details"
                      />
                    </div>
                  </div>
                  name
                  <div className="mb-3 col-md-4">
                    <label className="form-label">Coupon Status</label>
                    <select
                      ref={active_ref}
                      id="inputState"
                      className="default-select form-control wide"
                    >
                      <option value={"true"}>Active</option>
                      <option value={"false"}>Deactive</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-sm-9 d-flex justify-content-center align-items-center  w-70 text-end">
                      {resp_msg == 1 && (
                        <span className=" text-success">{api_resp_msg}</span>
                      )}
                      {resp_msg == 2 && (
                        <span className=" text-danger">{api_resp_msg}</span>
                      )}

                      {resp_msg == false && (
                        <span className=" text-danger">not working</span>
                      )}
                    </div>

                    <div className="mb-3 col-sm-3  w-70 text-end">
                      {isLoading ? (
                        <button
                          width={"100px"}
                          height={"50px"}
                          className="btn btn-primary p-2 "
                        >
                          <LoaderComp />
                        </button>
                      ) : (
                        <button
                          onClick={send_handler}
                          className="btn btn-primary p-2 "
                          style={{
                            fontSize: "16px",
                            height: "51px",
                            width: "95px",
                          }}
                        >
                          Create
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
    </>
  );
};
const All_coupons = ({state_update,setState_update}) => {
 const [data,setData]=useState([])

//  console.log("Coupon_code_render")
 const compareDates = (d1, d2) => {
   let date1 = new Date(d1).getTime();
   let date2 = new Date(d2).getTime();
  //  console.log(date1,date2)
   if (date1 > date2) {
     return true;
   }
   else {
    return false;
   }
 }

   useEffect(() => {
    const api=async ()=>{

      try {
        const resp= await all_coupons_service();
        
        const resp_data=resp.data.data
        // console.log(resp_data)
        if(resp.status===200){
         if(Array.isArray(resp_data)==true){
          // console.log("updating")
          setData(resp_data)
         }
          // compare date
          // console.log(resp_data,"resp_data")
          


        }
        // console.log(data,"data")

      } catch (error) {
        console.log(error)
      }
    

     
    }
    api()
   }, [state_update]);
  





  return (
    <div className="col-xl-12 col-lg-12">
      <div className="card p-4">

      <div className="row">

      {data.map((d=>{
          if(d.deleted==false){
            return <Coupon_code_render data={d} setState_update={setState_update}/>
          }
        }))}
</div>

      </div>
    
     
    </div>
  );
};
const Coupon_code_render=({data,setState_update})=>{ 
const [expiry_date_status,setExpiry_date_status]=useState(false)
// console.log("Coupon_code_render")
const compareDates = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();
  // console.log(date1,date2)
  if (date1 > date2) {
    return true;
  } else {
   return false;
  }
}
// console.log(data,"")
useEffect(()=>{
  setExpiry_date_status(compareDates(data.expire_date,Date.now()))
},[])

const delete_handler=async (e)=>{
const id=e.target.id;
//alert(id)
const resp=await delete_coupon_service(id);
// console.log(resp)
if(resp.status===200){
  setState_update(e=>e+1)
}

}




 return  <div className="col-xl-4  col-lg-6 col-sm-6">
  <div className="card  overflow-hidden border border-primary">
    <div className="card-body">
      <div className="">
        <div>
          <span>Name: {data.name}</span>
          <br/>
          <span>Details: {data.details}</span>
          <br/>
          <span>Coupon Code: {data.coupon_code}</span>
          <br/>
          <span>Expire Date: {new Date(data.expire_date).toDateString()}</span>
          <br/>
          
          
        </div>
       <hr />
       <div className="row">
       <div className="col-xl-12  col-lg-6 col-sm-6 text-end">
          {expiry_date_status?
           <button className="btn btn-success light">Not Expired</button>
          :
          <button className="btn btn-danger light">Expired</button>
          }
       </div>
       
       </div>
       <hr />
       <div className="col-xl-12  col-lg-6 col-sm-6 p-0 text-end">
        <button onClick={delete_handler} className="btn btn-danger" id={data._id}>Delete</button>
       </div>
      </div>
    </div>
  </div>
</div>

}
const App = (props) => {
  const [state_update,setState_update]=useState(0)

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          <Create_coupon setState_update={setState_update} />
          <br />
          <All_coupons state_update={state_update} setState_update={setState_update} />
        </div>
      </div>
    </>
  );
};

export default App;
