import React, { useEffect, useState } from "react";
import PrivatePage from "./panels/PrivatePage"
import PublicPage from "./panels/PublicPage"
import { useDispatch, useSelector } from "react-redux";
import { login_auth_check } from "./services/master_user";
import { login } from "./store/authSlice";
import Modal from "./components/Modal";

function App() {
  // alert("ok")
const dispatch=useDispatch()

 const login_state= useSelector(state=>state.auth.isLogin)


 useEffect(() => {
  const is_logged=login_auth_check()
  if(is_logged==true) {
    dispatch(login(true))
  }
  
 }, []);
  return <>
{/* <h1>{process.env.REACT_APP_API}</h1> */}
  {login_state? <PrivatePage/>:<PublicPage/>}
 
  </>;
}

export default App;
