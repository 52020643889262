export const is_empty_check=(...args)=>{
    console.log(args)
    let is_empty;
    args.map(d=>{
        console.log(d)
        if(d==="" || d===null|| d===undefined){
            is_empty=true 
        }
    })
    console.log({is_empty})
     if(is_empty===true){
        alert("one of field is empty")
    return true
     }
     else{
        return false;
     }
  }