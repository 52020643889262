import axios from "axios";

const token=localStorage.getItem("token")
export const manual_plan_purchase_calc_service=async({selected_user_id,selected_plan_id,is_admin_discount,admin_discount_amount})=>{
   
  try {
        const resp = await axios
        .post(process.env.REACT_APP_API +"/master_user"+ "/payment/plan_and_payments_calc",{
            selected_user_id:selected_user_id,
            selected_plan_id:selected_plan_id,
            is_admin_discount:is_admin_discount,
            admin_discount_amount:admin_discount_amount
        },
        { headers: {"Authorization" : `${token}`} });
        
     
       return resp;
      } catch (error) {
        console.log(error)
        return {
          msg: "internal error",
        };
      }
  }
export const manual_plan_purchase_service=async({selected_user_id,selected_plan_id,is_admin_discount,admin_discount_amount})=>{
   
   try {
         const resp = await axios
         .post(process.env.REACT_APP_API +"/master_user"+ "/payment/manual_plan_purchase",{
             selected_user_id:selected_user_id,
             selected_plan_id:selected_plan_id,
             is_admin_discount:is_admin_discount,
             admin_discount_amount:admin_discount_amount
         },
         { headers: {"Authorization" : `${token}`} });
         
         console.log(resp);
        return resp;
       } catch (error) {
         console.log(error)
         return {
           msg: "internal error",
         };
       }
   }

export const user_refrrals_service=async(id)=>{

    try {
          const resp = await axios
          .post(process.env.REACT_APP_API +"/master_user"+ "/user/user_referrals",{
            user_id:id
          },
          { headers: {"Authorization" : `${token}`} });
          
         //  console.log(resp);
         return resp;
        } catch (error) {
          console.log(error)
          return {
            msg: "internal error",
          };
        }
    }
export const get_all_commission_transaction_service=async()=>{
  
   try {
         const resp = await axios
         .get(process.env.REACT_APP_API +"/master_user"+ "/commission/all_commission",
         { headers: {"Authorization" : `${token}`} });
         
        //  console.log(resp);
        return resp;
       } catch (error) {
         console.log(error)
         return {
           msg: "internal error",
         };
       }
   }
export const user_commission_details_service=async()=>{
   
   try {
         const resp = await axios
         .get(process.env.REACT_APP_API +"/master_user"+ "/commission/user_commission_details",
         { headers: {"Authorization" : `${token}`} });
         
      
        return resp;
       } catch (error) {
         console.log(error)
         return {
           msg: "internal error",
         };
       }
   }
export const update_commission_transaction_service=async({id,details,status})=>{
   
   try {
         const resp = await axios
         .post(process.env.REACT_APP_API +"/master_user"+ "/commission/update_commission_details",{
             id:id,
             details:details,
             status:status,
         },
         { headers: {"Authorization" : `${token}`} });
         
     
        return resp;
       } catch (error) {
         console.log(error)
         return {
           msg: "internal error",
         };
       }
   }

//  payment details by id
export const payment_details=async({id,details,status})=>{
   
    try {
          const resp = await axios
          .post(process.env.REACT_APP_API +"/master_user"+ "/commission/update_commission_details",{
              id:id,
              details:details,
              status:status,
          },
          { headers: {"Authorization" : `${token}`} });
          
      
         return resp;
        } catch (error) {
          console.log(error)
          return {
            msg: "internal error",
          };
        }
    }