import React, { useEffect, useRef, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { all_user_accounts } from "../../services/master_user";
import { add_coupon_code_service } from "../../services/coupon";
import LoaderComp from "../../components/Loader";
import indian_states from "../../JSON_data/indian_states";
import { useNavigate } from "react-router-dom";
import Msg_display from "../../components/Msg_display";
// import { signup_service } from "../../services/master_user";


const Add_user = ({setState_update}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resp_msg, setResp_msg] = useState("");
  const [api_resp_msg, setApi_resp_msg] = useState("");
  const [show_create_form, setShow_create_form] = useState(false);
  
  
  const name=useRef()
  const email=useRef()
  const password=useRef()
  const city=useRef()
  const state=useRef()
  const zip_code=useRef()
  const mobile=useRef()
  const reffral_code_ref=useRef()


  

  return (
    <>
      <div className="col-xl-6 col-lg-12">
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            setShow_create_form(!show_create_form);
          }}
        >
          {show_create_form ? "Close" : "Add User"}
        </button>
        {show_create_form && (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add user</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
              <form>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Full Name</label>
                      <input
                      ref={name}
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Email</label>
                      <input
                       ref={email}
                        type="email"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Password</label>
                      <input
                        ref={password}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Mobile no.</label>
                      <input
                        ref={mobile}
                        type="number"
                        className="form-control"
                        placeholder="mobile no."
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label>City</label>
                      <input type="text" ref={city} className="form-control" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-2 w-100">
                      <label className="form-label">State</label>
                      <select
                      ref={state}
                        id="inputState"
                        className="default-select form-control wide"
                      >
                        <option selected="">Choose....</option>
                        {indian_states.map((d)=>{

                         return <option  key={d.key} >{d.name}</option>
                        })}
                       
                     
                      </select>
                    </div>
                    <div className="mb-3 col-md-2 w-100">
                      <label className="form-label">Zip</label>
                      <input ref={zip_code} type="number" className="form-control" />
                    </div>
                    <div className="mb-3 col-md-2 w-100">
                      <label className="form-label">Reffral Code</label>
                      <input ref={reffral_code_ref}  type="text" placeholder="reffral code" className="form-control" />
                    </div>
                  </div>
                
                <div className="row">
                  <div className="mb-3 col-md-12 text-center ">
                    <button type="submit" className="btn btn-primary  ">
                      Submit
                    </button>
                  </div>

                  
                </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
    </>
  );
};
const App = (props) => {
 
  const [data_state,setdata_state]=useState({is_loaded:null,msg:"",data_arr:[],render:true})
  useEffect(() => {
    // setdatarender(data)
    const handler = async () => {
      const resp = await all_user_accounts();
      // console.log(resp)
      // console.log(resp,"data");
      if(resp.status>=200){
       const data_arr=resp?.data?.data
       if(Array.isArray(data_arr)==true){
        const reverse=data_arr.reverse()
        setdata_state({is_loaded:true,msg:"loaded",data_arr:reverse,render:true})
       }
 


       }
       else{
        setdata_state({is_loaded:false,msg:"not loaded",data_arr:[],render:true})
       }
     


    };
    handler();
  }, []);

  const Data_render = () => {
    const navigation=useNavigate();
    const view_handler=(event)=>{
      localStorage.setItem("selected_user_id",event.target.id)
      navigation("/user_details")
    }

    
    return<>
    {Array.isArray(data_state.data_arr)==true?
    
    <>
    {data_state.data_arr.map((d,n)=>{
     
        return <tr>
       <td>{data_state.data_arr.length-n}</td>
        <td>{d.name}</td>
        <td>{d.email}</td>
        <td>{d.is_enrolled==true ?"plan taken":"none"}</td>
        <td>{new Date(d?.createdAt).toDateString()}</td>
        <td>
          {d.kyc_status==true?
          <span className="badge badge-rounded badge-success">Verified</span>
          :
          <span className="badge badge-rounded badge-warning">Panding</span>
          }
        </td>
        {/* <td>{d.amount}</td> */}
        <td><button className="btn btn-primary" id={d._id} onClick={view_handler}>view</button></td>

      </tr>


    }
    )}
    </>
    : <Msg_display status={false} msg={"data not available"}/>
  }
    </>
    
  };

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div class="row">
        
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        {/* <Add_user/> */}

        </div>


          <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Registerd Users</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive recentOrderTable">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                         
                        <th scope="col">SL no.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">plans</th>
                        <th scope="col">Date</th>
                        <th scope="col">kyc Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {/* {data_state?.data_arr.length==0 &&  <Msg_display status={false} msg={"data not available"}/>} */}
                      {data_state?.is_loaded==true?
                      <Data_render />
                      
                      :
                      <Msg_display status={data_state?.is_loaded} msg={"data not loaded"}/>
                      }
                        
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
