import { nanoid } from "nanoid";
import React from "react";

const data_render = (data_arr) => {
  // console.log(data_arr);
  if (Array.isArray(data_arr) == false) {
    // alert("data_render is not recived array");
    // console.log("its not array");
    return [];
  }
  return data_arr.map((d, n) => {
    const code = (
      d?.is_render==true?
        <tr key={nanoid()}>
      <td>
          <div key={nanoid()} style={{ textAlign: "left" }}>
            {d?.key}
          </div>
        </td>
        <td>
          <div key={nanoid()} style={{ textAlign: "left" }}>
            {d?.value}
          </div>
        </td>
      </tr>
        :""
    
    );
    return code;
  });
};
function Table2row({ data_arr,is_render }) {
  
  // console.log(data_arr,is_render)
  // example data
  // const data_arr = [
  //   {
  //     is_render: true,
  //     key: "Name",
  //     value: "ok",
  //   },
  //   {
  //     is_render: true,
  //     key: "Name",
  //     value: "ok",
  //   },
  //   {
  //     is_render: true,
  //     key: "Name",
  //     value: "ok",
  //   },
  //   {
  //     is_render: true,
  //     key: "Name",
  //     value: "ok",
  //   },
  //   {
  //     is_render: true,
  //     key: "Name",
  //     value: "ok",
  //   },
  // ];

  // note- it return only table , deploy it inside card-body
  return (
    <div className="row">
      <div div className="col-xl-12">
      
            <div className="table-responsive active-projects style-1">
              {is_render == true ? (
                <table id="empoloyees-tblwrapper " className="table">
                  {/* <thead>
                    <tr>
                      {table_head_arr.map((e) => {
                        return <th>{e}</th>;
                      })}
                    </tr>
                  </thead> */}

                  <tbody>{data_render(data_arr)}</tbody>
                </table>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
    
  );
}

export default Table2row;
