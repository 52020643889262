import React, { useState } from "react";
import style from "../App.css";

import { Routes, Route, Switch, useParams } from "react-router-dom";

import TopNav from "../components/Public/TopNav";

import Sidebar from "../components/Sidebar";

import Login from "../pages/public_pages/login";




function Page404(params) {

  // console.log(params)


  const link = useParams();


  return (
    <div className="d-flex column justify-content-center ">
      <br />

      <br />
      <div>
        <h2>{link["*"]}</h2>
        <h1>404 not found</h1>
      </div>
    </div>
  );
}

function App(props) {
 
  return (
    <div id="main-wrapper">
         {/* <TopNav /> */}
         <TopNav/>
         {/* <Sidebar/> */}
      {/* <div className="content-body bg-primary">
    </div> */}
       
   
     
      {/* <Sidebar/> */}
     <br />
     <br />
     <br />
     <br />
      <div className="container">
      <div className="row">
        <Routes>
          <Route path="" element={<Login name={"Admin login"}    />} />
          <Route path="home" element={<Login name={"Admin login"}   />} />
          <Route path="login" element={<Login name={"Admin login"}  />} />
          <Route path="*" element={<Page404 />} />
          {/* <Route path="*" element={<Page404 />} /> */}
        </Routes>
      </div>
    </div>
    </div>
  
  );
}

export default App;
