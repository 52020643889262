import React, { useEffect, useRef, useState } from "react";

import Page_titles from "../../components/Page_titles";

import Table2row from "../../components/Table2row";
import { payment_transaction_details } from "../../services/data";
import Msg_display from "../../components/Msg_display";

//importAANT FUNCTION TO RENDER
// it array of key and value, then  generate table data where name: value will returned which then get rendered



const Purchase_details_component = ({ details_data_state }) => {
  // console.log(details_data_state)
  const details_data_obj = details_data_state.data_obj;
  const is_data_load = details_data_state.is_loaded;
  const [data_state, setData_state] = useState({
    is_loaded: false,
    render: true,
    data_arr: [],
    mag: "",
  });

  useEffect(() => {
    if (is_data_load == true) {
        // console.log(details_data_obj,"details_data_obj")
        // it purchase_details_data will give purchase details
        const purchase_details_data =
        details_data_obj?.purchase_details;
        const transaction_id =
        details_data_obj?.transaction;
        const enrolled_plan = details_data_obj?.plan;
        const data_arr = [
          {
            is_render: true,
            key: "Plan Name",
            value: enrolled_plan?.plan_name,
          },

          {
            is_render: true,
            key: "Original plan price",
            value: purchase_details_data?.original_plan_price,
          },
          // discounts
          {
            is_render: true,
            key: "Discount",
            value:
              purchase_details_data?.is_discount == true
                ? `${purchase_details_data?.discount_calc_details?.discount_value}(${purchase_details_data?.discount_calc_details?.discount_percentage}%)`
                : "not avaliable",
          },
          {
            is_render: true,
            key: "Coupon Discount",
            value:
              purchase_details_data?.is_coupon == true
                ? `${purchase_details_data?.discount_calc_details?.coupon_discount_value}(${purchase_details_data?.discount_calc_details?.coupon_discount_percentage}%)`
                : "not avaliable",
          },
          {
            is_render: true,
            key: "Admin Discount",
            value:
              purchase_details_data?.is_admin_discount == true
                ? `${purchase_details_data?.discount_calc_details?.admin_discount_amount} rs` : "not avaliable",
          },
          {
            is_render: true,
            key: "Upgrade",
            value:
              purchase_details_data?.is_upgraded == true
                ? `yes` : "no",
          },
          {
            is_render: purchase_details_data?.is_upgraded == true?true:false,
            key: "Previous CSV",
            value:
              purchase_details_data?.discount_calc_details?.previous_purchased_plan_csv 
            
          },

          // {render:true,key: "Discount", value: purchase_details_data.original_plan_price},
          // {render:true,key: "Coupon Discount", value: purchase_details_data.original_plan_price },

          {
            is_render: true,
            key: "Final Plan Price",
            value: purchase_details_data?.all_calculations?.final_plan_price,
          },
          {
            is_render: true,
            key: "Final Plan Price(without gst)",
            value:
              purchase_details_data?.all_calculations?.plan_price_without_gst,
          },
          {
            is_render: true,
            key: "GST",
            value: purchase_details_data?.all_calculations?.gst_percentage+"%",
          },
          {
            is_render: true,
            key: "GST(in amount)",
            value: purchase_details_data?.all_calculations?.gst_amount,
          },
          {
            is_render: true,
            key: "CSV",
            value:
              purchase_details_data?.all_calculations
                ?.commission_sales_value_csv,
          },
          {
            is_render: true,
            key: "Razorpay Charges Amount",
            value:
              purchase_details_data?.all_calculations?.razorpay_charges_amount,
          },
          {
            is_render: true,
            key: "Plan Commision Percentage",
            value: purchase_details_data?.all_calculations?.commision_percentage,
          },
          {
            is_render: true,
            key: "Referral Commission Amount",
            value: purchase_details_data?.all_calculations?.commission_amount,
          },
          {
            is_render: true,
            key: "TDS Percentage",
            value: purchase_details_data?.all_calculations?.TDS_percentage+"%",
          },
          {
            is_render: true,
            key: "TDS(in amount)",
            value: purchase_details_data?.all_calculations?.TDS_amount,
          },
          {
            is_render: true,
            key: "Referral Commision Amount(after TDS)",
            value:
              purchase_details_data?.all_calculations
                ?.commision_amount_after_TDS,
          },
        ];
        setData_state({
          is_loaded: true,
          render: true,
          data_arr: data_arr,
          msg: "",
        });
      
    }
  }, [details_data_state]);
  return (
    <div className="card">
      <div className="card-header">Plan Purchase Details</div>
      <div className="card-body p-2">
        <div className="tbl-caption"></div>
        {is_data_load == true ? (
          <div className="table-responsive active-projects style-1">
            <table className="table">
              <tbody><Table2row data_arr={data_state.data_arr} is_render={true} /> </tbody>
            </table>
          </div>
        ) : (
          <div className="">data not loaded</div>
        )}
      </div>
    </div>
  );
};
const Purchase_details2_component = ({ details_data_state }) => {
  // console.log(details_data_state)
  const details_data_obj = details_data_state.data_obj;
  const is_data_load = details_data_state.is_loaded;
  const [data_state, setData_state] = useState({
    is_loaded: false,
    render: true,
    data_arr: [],
    mag: "",
  });




  useEffect(() => {
    if (is_data_load == true) {
      // console.log(details_data_obj,"details_data_obj")
      // it purchase_details_data will give purchase details
      const purchase_details =
      details_data_obj?.purchase_details;
      const transaction_details =
      details_data_obj?.transaction;
      const enrolled_plan_details = details_data_obj?.plan;
      let data_arr=[]
      if (transaction_details?.is_cash_payment == true) {
        data_arr=[
          {   is_render: true,
            key: "Plan Name",
            value: enrolled_plan_details?.plan_name || "not loaded",
          },
          {   is_render: true,
            key: "Plan Price",
            value: enrolled_plan_details?.price || "not loaded",
          },
          {   is_render: true,
            key: "Purchased Price",
            value:
              purchase_details?.discount_calc_details?.final_plan_price ||
              "not loaded",
          },
          {   is_render: true,
            key: "Commission",
            value:
              enrolled_plan_details?.commision_percentage + "%" || "not loaded",
          },
          {   is_render: true,
            key: "Transction Date",
            value: transaction_details?.createdAt || "not loaded",
          },
          {   is_render: true,
            key: "Transction ID",
            value: transaction_details?._id || "not loaded",
          },
          {   is_render: true,
            key: "Transction Date",
            value: transaction_details?.payment_method || "not loaded",
          },
        ];
      } else {
        data_arr=[
         
         
          {   is_render: true,
            key: "Purchased Price",
            value:
              purchase_details?.discount_calc_details?.final_plan_price ||
              "not loaded",
          },
         
          {   is_render: true,
            key: "Transction Date",
            value: transaction_details?.createdAt || "not loaded",
          },
          {   is_render: true,
            key: "Transction ID",
            value: transaction_details?._id || "not loaded",
          },
          {   is_render: true,
            key: "Transction Mode",
            value: transaction_details?.payment_method || "not loaded",
          },
          {   is_render: true,
            key: "Transction ID",
            value: transaction_details?._id || "not loaded",
          },
          {   is_render: true,
            key: "Razorpay Payments ID",
            value: transaction_details?.payment_id || "not loaded",
          },
        ];
      }
      // console.log(data_arr)
      setData_state({
        is_loaded: true,
        render: true,
        data_arr: data_arr,
        msg: "",
      });
    }

  
   
}, [details_data_state]);
  
  return (<>
  <div className="card">
      <div className="card-header">Transaction Details</div>
      <div className="card-body p-2">
        <div className="tbl-caption"></div>
        {is_data_load == true ? (
          <div className="table-responsive active-projects style-1">
            <table className="table">
              <tbody><Table2row data_arr={data_state.data_arr} is_render={true} /> </tbody>
            </table>
          </div>
        ) : (
          <div className="">data not loaded</div>
        )}
      </div>
    </div>
    </>
  );
};
function User_details(props) {

 const selected_payment_id=localStorage.getItem('selected_payment_id')
 const [details_data_state, setDetails_data_state] = useState({
  is_loaded: null,
  render: true,
  data_obj: {},
  mag: "",
});


  useEffect(() => {
    // setdatarender(data)
    const handler = async () => {
      const resp = await payment_transaction_details(selected_payment_id);
      const data = resp?.data.data;
      console.log(data)
    if(resp.status>=200){
      setDetails_data_state({
        is_loaded: true,
        render: true,
        data_obj:data,
        mag: "",
      })
    }
    else{
      setDetails_data_state({
        is_loaded: false,
        render: true,
        data_obj:{},
        mag: "data not loaded",
      })
    }
    
      
    };
    handler();
  }, []);
 

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          {/* user info */}
         
          

          <div className="col-xl-12">
            <br />
            <br />
            <div className="card p-0">
              <div className="card-header">User Info</div>

              <div className="card-body">
                <div className="row">
                 
                  <div className="col-xl-6">
                    {details_data_state.is_loaded==true?
                       <Purchase_details_component
                       details_data_state={details_data_state}
                     />
                    :
                    <Msg_display status={details_data_state.is_loaded} msg={details_data_state.msg}/>
                    }
                 
                  </div>
                  <div className="col-xl-6">
                  {details_data_state.is_loaded==true?
                     <Purchase_details2_component
                     details_data_state={details_data_state}
                   />
                    :
                    <Msg_display status={details_data_state.is_loaded} msg={details_data_state.msg}/>
                    }
                 
                   
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
       

       
        
        </div>
      </div>
    </>
  );
}

export default User_details;
