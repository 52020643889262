import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {login,check} from "../../store/authSlice"
// import Page_titles from "../../components/Page_titles";
import { login_service } from "../../services/master_user";
import Msg_display from "../../components/Msg_display";



const Login = (props) => {
  const [msg_state,setMsg_state]=useState({status:"",msg:""})
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const enterd_email_id=useRef()
    const enterd_passwor=useRef()
  const login_handler = async (e) => {
    e.preventDefault()
    const email_value=enterd_email_id.current.value
    const passoword_value=enterd_passwor.current.value
    setMsg_state({status:null,msg:"login"})
    const resp=await login_service(email_value,passoword_value)
    // console.log(resp,"data")
    if(resp.status>=200){
      //alert("login successful")
      const token=resp?.data?.token
      localStorage.setItem('token',token)
      dispatch(login(true))
      // setMsg_state({status:true,msg:"login successful"})
      navigate("/");
      window.location.reload();
    }
    else{
      const data=resp?.response?.data
      console.log(resp,"resp")
      setMsg_state({status:false,msg:data?.msg})
    }
   
  };

  return (
    <>
      <h3> {props.name} </h3>

      <div className="col">
        <div className="card ">
          <div className="card-body">
            <div className="basic-form">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      ref={enterd_email_id}
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Password</label>
                  <div className="col-sm-9">
                    <input
                    ref={enterd_passwor}
                      type="password"
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="row">
                <div className="mb-3 col-md-12 w-100 text-center">
                  <Msg_display status={msg_state.status} msg={msg_state?.msg}/>
                  </div>
                  <div className="mb-3 col-md-12 w-100 text-center">
                    <button
                      type="submit"
                      onClick={login_handler}
                      className="btn btn-primary w-50 "
                    >
                      Admin Login
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-2 w-100">
                    {/* <Link to="/signup">
                      <h5 className="text-center">
                        not an Account ?{" "}
                        <span className="text-blue">Create Account</span>
                      </h5>
                    </Link> */}
                    {/* <h4 className="text-center">or</h4> */}
                    {/* <Link to="/forget-passoword">
                      <h5 className="text-center">
                        forget Passowrd ?{" "}
                        <span className="text-blue">Reset ypur password</span>
                      </h5>
                    </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
