import React, { useState } from "react";

function Modal({ show_modal, setShow_modal,children}) {
  const close_handler = () => {
    setShow_modal(false);
  };

  return (
    <>
    {/* backdrop */}
    <div
        className=" d-flex justify-content-center align-items-center "
        style={{
            zIndex:4,
          fontSize: "25px",
        //   borderRadius: "50%",
          position: "fixed",
          backgroundColor:"#E2E2E2",
          opacity:"0.2",
          right: "0",
          width: "100vw",
          top: "0",
          height: "100%",
          textAlign: "end",
        //   color: "white",
        //   boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
        }}
        onClick={close_handler}
      >
        {/* <span>X</span> */}
      </div>
    <div
      style={{
        position: "fixed",
        margin: "auto",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        width: "80%",
        minWidth: "400px",
        maxWidth:"800px",
        height: "500px",
        backgroundColor: "white",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        zIndex:"5"
      }}
    >
      <div
        className="bg-primary d-flex justify-content-center align-items-center "
        style={{
            // zIndex:4,
          fontSize: "25px",
          borderRadius: "50%",
          position: "absolute",
          right: "5px",
          width: "30px",
          top: "5px",
          height: "30px",
          textAlign: "end",
          color: "white",
          boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
        }}
        onClick={close_handler}
      >
        <span>X</span>
      </div>
      
      <div>{children}</div>
    </div>
    </>
  );
}

export default Modal;
