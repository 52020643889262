import axios from "axios";

// "email":"krishna@gmail.com",
//  "password":"123456",
//
const token=localStorage.getItem("token")

export const login_service = async (email, password) => {
  try {
    const resp = await axios.post(process.env.REACT_APP_API +"/master_user"+ "/login", {
      email: email,
      password: password,
      name: "kjhj",
      master_code: "789963",
    });

    return resp
   
  } catch (error) {
    console.log(error)
    return error
  }


};


export const login_auth_check =  () => {
  const token = localStorage.getItem("token");
  console.log(token)
  if (token == "" || token==null || token==undefined) {
    return false;
  } else {
    return true;
  }
};
export const all_user_accounts=async()=>{
  
    try {
        const resp = await axios.get(process.env.REACT_APP_API +"/master_user"+"/all_users",
        { headers: {"Authorization" : `${token}`} });
      
   
     return resp
      } catch (error) {
        return {
          msg: "internal error",
          login_status: false,
        };
      }
}
export const user_details=async(user_id)=>{
  try {
      const resp = await axios.post(process.env.REACT_APP_API +"/master_user"+"/user_details",{
        user_id:user_id
      },
      { headers: {"Authorization" : `${token}`} });
      
      // console.log(resp);
     return resp;
    } catch (error) {
      console.log(error)
      return {
        msg: "internal error",
      };
    }
}