import React, { useEffect, useRef, useState } from "react";
import { user_details } from "../../services/master_user";
import Page_titles from "../../components/Page_titles";
import { kyc_action_service } from "../../services/kyc";

function User_kyc_details(props) {
  const kyc_status = useRef();
  const kyc_status_messgae = useRef();
  const [apidata, setapidata] = useState("");
  const [data, setdata] = useState([]);
  const id = localStorage.getItem("selected_user_id");

  useEffect(() => {
    // setdatarender(data)
    const handler = async () => {
      const resp = await user_details(id);

      setapidata(resp.data);
      // console.log(resp,"data");
      const user_detail = resp?.data?.user_detail;
   
      if (user_detail == undefined) {
        alert("no data");
      } 
      else if (user_detail.kyc_upload == true) {
      

      setdata([
          { key: "Name", value: user_detail.kyc.name },
          { key: "Adhar Card No.", value: user_detail.kyc.adhar_card },
          { key: "PAN Card", value: user_detail.kyc.pan_card },
          {
            key: "Bank Name",
            value: user_detail.kyc.bank_account_details.bank_name,
          },
          {
            key: "Bank Account No",
            value: user_detail.kyc.bank_account_details.account_number,
          },
          {
            key: "IFSC code",
            value: user_detail.kyc.bank_account_details.ifsc_code,
          },
        ]);
      }
    };
    handler();
  }, []);
  const save_handler = async (e) => {
    e.preventDefault();
    try {
      const kyc_status_value = kyc_status.current.value;
      const kyc_status_messgae_value = kyc_status_messgae.current.value;
      const kyc_resp = await kyc_action_service(
        id,
        kyc_status_value,
        kyc_status_messgae_value
      );

      if(kyc_resp.status==200){
        alert("updated sucessfully")
      }
    } catch (error) {}
  };

  const data_render = (data_arr) => {
  
    return data_arr.map((d, n) => {
      const code = (
        <tr key={(n = "key")}>
          <td>
            <div style={{ textAlign: "left" }}>{d?.key}</div>
          </td>
          <td>
            <div style={{ textAlign: "left" }}>{d?.value}</div>
          </td>
        </tr>
      );
      return code;
    });
  };
  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1">
                  <div className="tbl-caption"></div>
                  <table className="table">
                    <thead></thead>
                    <tbody>{data_render(data)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-3">
                <h3>action</h3>
                <div className="basic-form">
                  <form>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        KYC status
                      </label>
                      <div className="col-sm-9">
                        <select
                          ref={kyc_status}
                          name=""
                          id=""
                          className="default-select form-control wide"
                        >
                          <option value="verified">Verified</option>
                          <option value="not_verified">Not Verified</option>
                        </select>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">Message</label>
                      <div className="col-sm-9">
                        <input
                          ref={kyc_status_messgae}
                          type="text"
                          className="form-control"
                          placeholder="messgae/comment"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-2 w-100 text-center">
                        <button
                          onClick={save_handler}
                          type="submit"
                          className="btn btn-primary w-50 "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-2 w-100"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User_kyc_details;
