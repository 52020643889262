import React, { useEffect, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { all_user_accounts } from "../../services/master_user";
import { useNavigate } from "react-router-dom";
import Msg_display from "../../components/Msg_display";

const App = (props) => {
const navigation=useNavigate();
const [data_state,setdata_state]=useState({is_loaded:null,msg:"",data_arr:[],render:true})
  const [data, setdata] = useState([]);
  useEffect(() => {
    // setdatarender(data)
    const handler = async () => {
      const resp = await all_user_accounts();
      if(resp.status>=200){
        const data_arr=resp?.data?.data
   setdata_state({is_loaded:true,msg:"loaded",data_arr:data_arr,render:true})
 
 
        }
        else{
         setdata_state({is_loaded:false,msg:"not loaded",data_arr:[],render:true})
        }
    }
    handler();
  }, []);

  const view_handler=(event)=>{
    localStorage.setItem("selected_user_id",event.target.id)
    navigation("/kyc_details")
  }

  const Data_render = () => {
  
    // return data.map((d)=>{
   
    //    
    //   })
    return<>
    {Array.isArray(data_state.data_arr)==true?
    
    <>
    {data_state.data_arr.map((d)=>{
     
     if(d.kyc_upload==true && d.kyc_status==false){
            return <tr>
            <td>{d.name}</td>
            <td>{d.email}</td>
    
            <td>Date</td>
            <td>
              <span className="badge badge-rounded badge-warning">Panding</span>
            </td>
            <td><button className="btn btn-primary" id={d._id} onClick={view_handler}>view</button></td>
          </tr>
          }


    }
    )}
    </>
    : <Msg_display status={data_state.is_loaded} msg={"data not available"}/>
  }
    </>
  
    
      
    
  };

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div class="row">
          <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Registerd Users</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive recentOrderTable">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Date</th>
                        <th scope="col">kyc Status</th>
                        <th scope="col">Action</th>

                      </tr>
                    </thead>
                    <tbody>
                    {data_state?.is_loaded==true?
                      <Data_render/>
                      :
                      <Msg_display status={data_state.is_loaded} msg={"data not loaded"}/>
                      }
                        
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
