import React, { useEffect, useRef, useState } from "react";
import Page_titles from "../../components/Page_titles";

import { all_plans_service, discount_update } from "../../services/plan";

import { is_empty_check } from "../../utility/check";
import Loader from "../../components/Loader";
import Msg_display from "../../components/Msg_display";
import PreLoader from "../../components/PreLoader";

const Data_render = ({ data, state_updater }) => {
   const [show, setShow] = useState(true);
   const data_obj={
    price:data.price,
    commision_percentage:data.commision_percentage,
    is_discount:data.is_discount,
    discount_percentage:data.discount_percentage,
    discount_amount:"",
    // discount_price:,
    gst:data.gst,
    gst_amount:"",
    

   }



  return (
    <div className="col-xl-6 "  style={show?{minHeight:"500px"}:{minHeight:"400px"}}ey={data._id}>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{data.plan_name}</h4>
        </div>
        <div className="card-body">
          <div className="row" key={data._id}>
            <div className=" col-sm-12">
              <div className="card text-white bg-primary">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Commission :</span>
                    <strong>{data.commision_percentage}%</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Price :</span>
                    <strong>{data.price} </strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Discount Percentage :</span>
                    {data.discount_percentage != undefined
                      ? data.discount_percentage +"%"
                      : "Not available"}
                  </li>
              
                  <li className="list-group-item d-flex justify-content-between">
                    <span className="mb-0">Discount Status :</span>
                    {data.is_discount ? (
                      <div className="btn btn-success light ">Active </div>
                    ) : (
                      <div className="btn btn-danger light ">Deactive </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* discount details */}

          <br />
          {/* all calculations */}
        <div className="row">

        <div className=" col-12 text-end">

            <button className="btn btn-primary" onClick={()=>{
                setShow(!show)
            }}>More Details {show && "^"}</button>
        </div>

      {show &&<div>
          <h5>Calculations</h5>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              Price(without gst):
            </span>{" "}
            <span>{data?.calculations?.plan_price_without_gst}</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              GST:
            </span>{" "}
            <span>{data?.calculations?.gst_percentage}%</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              GST (in amount):
            </span>{" "}
            <span>{data?.calculations?.gst_amount}</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              Razorpay Charges:
            </span>{" "}
            <span>{data?.calculations?.razorpay_charges_percentage}%</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              Razorpay Charges(in amount):
            </span>{" "}
            <span>{data?.calculations?.razorpay_charges_amount}</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              TDS:
            </span>{" "}
            <span>{data?.calculations?.TDS_percentage}%</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              TDS (in amount):
            </span>{" "}
            <span>{data?.calculations?.TDS_amount}</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              C.S.V:
            </span>{" "}
            <span>{data?.calculations?.commission_sales_value_csv}</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              Commission (in amount):
            </span>{" "}
            <span>{data?.calculations?.commission_amount}</span>
          </div>
          <div>
            <span style={{ width: "250px", display: "inline-block" }}>
              Commission (after TDS):
            </span>{" "}
            <span>{data?.calculations?.commision_amount_after_TDS}</span>
          </div>
          <br />
          <Update_discount state_updater={state_updater} data={data} />


          </div>}


        </div>


          
       
        </div>
      </div>
    </div>
  );
};
const Update_discount = ({ data, state_updater }) => {
  const [update, setUpdate] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [resp_msg, setResp_msg] = useState(false);

  const discount_status = useRef();
  const discount_percentage = useRef();

  const data_send_handler = async (a) => {
    a.preventDefault();

    try {
      setResp_msg(false); // when reloaqd, or agin click on save, msg must be get hide
      const plan_id = a.target.id;
      const discount_status_value = discount_status.current.value;
      const discount_percentage_value = discount_percentage.current.value;
      const is_empty = is_empty_check(
        plan_id,
        discount_status_value,
        discount_percentage_value
      );
      if (is_empty == true) {
        return;
      }
      // checking percentage value
      if (
        Number(discount_percentage_value) < 0 ||
        Number(discount_percentage_value) >= 100
      ) {
        alert("discount must be positive and smaller than 100");
        return;
      }

      // console.log(discount_status_value, discount_percentage_value);
      setIsLoading(true);
      const resp = await discount_update(
        plan_id,
        discount_status_value,
        discount_percentage_value
      );
      setUpdate((d) => d + 1);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      setResp_msg(true);
      state_updater((d) => d + 1);
      // setIsLoading(false);
    } catch (error) {
      alert("not working,check your network connection");
      // console.log(error);
    }
  };

  return (
    <div className="basic-form">
      <form>
        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label">
            Change Discount status
          </label>
          <div className="col-sm-9">
            <select
              ref={discount_status}
              name=""
              id=""
              className="default-select form-control wide"
            >
              <option value="true">Activate</option>
              <option value="false">Deactivate</option>
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="col-sm-3 col-form-label">{"Disount"}:</label>
          <div className="col-sm-9">
            <input
              ref={discount_percentage}
              type="number"
              className="form-control"
              placeholder="In percentage"
            />
          </div>
        </div>
        <div className="row">
          <div className=" mb-3 d-flex  justify-content-center align-items-center col-sm-9 text-end ">
            {resp_msg && (
              <span className=" text-success">submitted sucessfully</span>
            )}
          </div>
          <div className="mb-3 col-sm-3  w-70 text-end">
            {isLoading ? (
              <button className="btn btn-primary p-1  ">
                <Loader />
              </button>
            ) : (
              <button
                id={data._id}
                onClick={data_send_handler}
                type="submit"
                className="btn btn-primary  "
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
const App = (props) => {
  const [update, setUpdate] = useState(1);
  const [plans_data, setplans_data] = useState([]);
  const [loader, setLoader] = useState({is_loaded:null,msg:""});

  useEffect(() => {
    // console.log("check");
    const handler = async () => {
      const resp = await all_plans_service();
      // console.log(resp);
      if (resp.status >= 200 ) {
        setplans_data(resp.data.data);
        setLoader({is_loaded:true,msg:"data loaded"})
        // console.log(resp.data.data);
      }
      if (resp.status >= 400 ) {
        setplans_data([]);
        setLoader({is_loaded:"false",msg:"data not loaded"})
        // console.log(resp.data.data);
      }
      

      // console.log(plans_data);
    };
    handler();
  }, [update]);
  // console.log(plans_data);

  return (
    <>
      <Page_titles name={props.name} />
      <div className="container-fluid">
        <div className="row">

       
        <PreLoader status={loader.is_loaded} msg={"not loaded"}>

        {
          loader.is_loaded==true?
          plans_data.map((d, n) => {
            return <Data_render state_updater={setUpdate} key={n} data={d} />;
          })
          :
        ""
          
        }


        </PreLoader>
     
         
        </div>
      </div>
    </>
  );
};

export default App;
