
import axios from "axios";

// "email":"krishna@gmail.com",
//  "password":"123456",
//
const token=localStorage.getItem("token")

export const all_plans_service=async()=>{

  try {
    
    const resp = await axios.get(process.env.REACT_APP_API + "/master_user"+"/all_plan",
    { headers: {"Authorization" : `${token}`} });
    
    return resp

  } catch (error) {
   console.log(error)
    return error
  }
}

export const update_meeting_link_service=async(meeting_link,selected_plan_id,message)=>{
    try {
        const resp = await axios.post(process.env.REACT_APP_API + "/master_user"+"/update_meeting_link",{
            selected_plan_id:selected_plan_id,
            meeting_link:meeting_link,
            message:message
        },
        { headers: {"Authorization" : `${token}`} });
        
        console.log(resp);
       return resp;
      } catch (error) {
        console.log(error)
        return {
          msg: "internal error",
        };
      } 
}
export const discount_update=async(plan_id,discount_status,discount_percentage)=>{
  try {
      const resp = await axios.post(process.env.REACT_APP_API + "/master_user"+"/discount_on_plan",{
        discount_status:discount_status,
        discount_percentage:discount_percentage,
        plan_id:plan_id
      },
      { headers: {"Authorization" : `${token}`} });
      
     
     return resp;
    } catch (error) {
      console.log(error)
      return {
        msg: "internal error",
      };
    } 
}