import React, { useEffect, useRef, useState } from "react";
import { user_details } from "../../services/master_user";
import Page_titles from "../../components/Page_titles";
import { kyc_action_service } from "../../services/kyc";
import {
  manual_plan_purchase_calc_service,
  manual_plan_purchase_service,
  user_refrrals_service,
} from "../../services/user";
import { all_plans_service } from "../../services/plan";
import { nanoid } from "nanoid";
import Modal from "../../components/Modal";
import Msg_display from "../../components/Msg_display";
import Show_hide from "../../components/Show_hide";
import Table2row from "../../components/Table2row";
import User_kyc from "./user_kyc"
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../components/PreLoader";
// import { kyc_action_service } from "../../services/kyc";
//importAANT FUNCTION TO RENDER
// it array of key and value, then  generate table data where name: value will returned which then get rendered

const Data_render = ({ data, n,update_state_function }) => {
  const navigation = useNavigate();
  const [modal_for_view, setModal_for_view] = useState(false);

  // Modal for more details
  // console.log(data)
  const slno=n
  const date = data.createdAt + "";
  const show_date = date.slice(0, 10);

//   user releted
  const user_email = data?.email;
  const user_mobile = data?.mobile;
  const enrolled_plan=data?.enrolled_plan?.plan_name;
  const user_name=data?.name;
  const user_id = data?._id;
  const enrolled_status=data?.is_enrolled;


  const view_handler = (event) => {
    // alert("ok")
    localStorage.setItem("selected_user_id", event.target.id);
    update_state_function()
    window.location.reload();
  };


  return (
    <tr key={data._id}>
      <td>
        <span>{slno}</span>
      </td>
      <td>
        <span>{user_name}</span>
      </td>
      <td>
        <span>{user_email}</span>
      </td>
      <td>
        <span>{user_mobile}</span>
      </td>
   
      <td>
        <span>{show_date}</span>

      </td>
     

      <td>
        {enrolled_status ? (
          <span className="badge badge-success light border-0">yes</span>
        ) : (
          <span className="badge badge-danger light border-0">not</span>
        )}
      </td>
      <td>{enrolled_status==true?enrolled_plan:""}</td>
    
  
      <td>
        <button id={user_id} onClick={view_handler} className="btn btn-primary">
          View
        </button>
      </td>
    
      
    
    </tr>
  );
};
const User_referrals_list_Component = ({ selecetd_user_id,update_state_function,update_state }) => {
  console.log(selecetd_user_id,"selecetd")
  const [data_state, setdata_state] = useState({
    is_loaded: null,
    msg: "data not loaded",
    is_render: false,
    data_arr: [],
  });

useEffect(() => {
  const api_handler = async () => {
    //  const id=null
    const resp = await user_refrrals_service(selecetd_user_id);
    console.log(resp,"resp")
    if (resp.status == 200) {
      const data_arr = resp?.data?.data;
      if (Array.isArray(data_arr) == true) {
        if (data_arr.length > 0) {
          console.log(data_arr)
          setdata_state({
            is_loaded: true,
            msg: "",
            is_render: false,
            data_arr: data_arr,
          });
        } else {
          setdata_state({
            is_loaded: false,
            msg: "data not avaliable",
            is_render: false,
            data_arr: [],
          });
        }
      }
    }
  };
  api_handler()
}, [update_state]);
  // user_refrrals_service
  return (
    <>
     
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1">
            <table id="empoloyees-tblwrapper " className="table">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Date</th>
                  <th>Enrolled</th>
                  <th>Plan name</th>
                  <th>User details</th>
                </tr>
              </thead>
              
              {data_state.is_loaded == true ? (
                <tbody>
                {console.log(data_state)}
                {Array.isArray(data_state.data_arr)==true?
                data_state.data_arr.map((data,n)=>{
                 return  <Data_render update_state_function={update_state_function}  data={data} n={n}/>
                })
             
                :"not loaded"
                 
                }
              
                    { }
                

                    {/* return <Table_data_render data={data}  />; */}
                  
                </tbody>
              ) : (
                <>
                  <br />
                  <br />
                  <Msg_display
                    status={data_state.is_loaded}
                    msg={data_state.msg}
                  />
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const Plan_purchase_manual_by_admin_component = ({
  selected_user_id,
  user_details_data_state,
  update_state_function
}) => {
  const [show_modal, setShow_modal] = useState(false);
  const user_detail_data_obj = user_details_data_state.data_obj;
  const is_data_load = user_details_data_state.is_loaded;
  const [calculation_data, setCalculation_data] = useState({
    render: false,
    data_arr: [],
    msg: "loading data..",
  });
  const [data_state, setData_state] = useState({
    status: "",
    msg: "",
  });
  const [all_plans, setAll_plans] = useState({
    render: null,
    data_arr: [],
    msg: "loading data..",
  }); //if render is true then only render else show msg what ever the problem, it will not casuse any error
  // note- show only all that plan which is higher than enrolled plan
  //if user not enrolled , then show all plans
  const selected_plan_id_ref = useRef();
  const admin_discount_amount_ref = useRef();
  // it will show the calculation
  const req_obj_generate = () => {
    const obj = {
      selected_user_id: selected_user_id,
      selected_plan_id: selected_plan_id_ref.current.value,
      is_admin_discount: false,
      admin_discount_amount: admin_discount_amount_ref.current.value,
    };
    // note- if amount enter then admin_discount will be true else false
    if (admin_discount_amount_ref.current.value == "") {
      let x = "";
    } else {
      obj.is_admin_discount = true;
    }
    return obj;
  };
  const calculation_btn_handler = async (e) => {
    e.preventDefault();
    setShow_modal(!show_modal);
    // alert("hello")
    const obj = req_obj_generate();
    // seding data to api
    const resp = await manual_plan_purchase_calc_service(obj);

    if (resp.status == 200) {
      // extract data from resp, make data arry that will get render using data render
      const data = resp?.data;
      const price_details = data?.price_details;
      const plan_details = data?.plan_details;

      // if final amount is negative means user have given discount more than price

      const data_arr = [
        { is_render: true, key: "Plan Name", value: plan_details?.plan_name },
        {
          is_render: true,
          key: "Original plan price",
          value: price_details?.orignal_plan_price + "rs",
        },
        // discounts
        {
          is_render: true,
          key: "Discount",
          value:
            price_details?.is_discount == true
              ? `${price_details?.discount_value}rs (${price_details?.discount_percentage}%)`
              : "not avaliable",
        },
        {
          is_render: true,
          key: "Coupon Discount",
          value:
            price_details?.is_coupon == true
              ? `${price_details?.coupon_discount_value}(${price_details?.coupon_discount_percentage}%)`
              : "not avaliable",
        },
        {
          is_render: true,
          key: "Admin Discount(amount)",
          value:
            price_details?.is_admin_discount == true
              ? `${price_details?.admin_discount_amount}rs`
              : "not avaliable",
        },
        {
          is_render: true,
          key: "Previous Plan CSV",
          value:
            price_details?.previous_purchased_plan_csv != undefined
              ? price_details?.previous_purchased_plan_csv + "rs"
              : "not avaliable",
        },
        {
          is_render: true,
          key: "Final Plan Price",
          value: price_details?.final_plan_price + "rs",
        },
        {
          is_render: true,
          key: "Final Plan Price(without gst)",
          value: price_details?.plan_price_without_gst + "rs",
        },
        { is_render: true, key: "GST", value: price_details?.gst + "%" },
        {
          is_render: true,
          key: "GST(in amount)",
          value: price_details?.gst_value + "rs",
        },
      ];
      // if final price is negative means wrong discount
      if (price_details.final_plan_price < 1) {
        // alert("ok");
        setCalculation_data({
          is_loaded: false,
          render: true,
          data_arr: data_arr,
          msg: "Final Price cannot be negative, please decrease discount amount",
        });
        return;
      }
      setCalculation_data({
        is_loaded: true,
        render: true,
        data_arr: data_arr,
        msg: "",
      });
    } else {
      setCalculation_data({
        render: false,
        data_arr: [],
        msg: "data not loaded",
      });
    }
  };
  const submit_handler = async (e) => {
    e.preventDefault();
    setData_state({status:null,msg:""})  
    const obj = req_obj_generate();
    const resp = await manual_plan_purchase_service(obj);
   
    if(resp.status>=200){
     update_state_function();
     setData_state({status:true,msg:"Purchasing is Successful"});
     setShow_modal(false);
    }
    else{
      setShow_modal(false);
      setData_state({status:false,msg:"Purchasing is failed"});
    }
    // alert("ok")
  };
  useEffect(() => {
    const handler = async (e) => {

      if (user_detail_data_obj != undefined) {
        const is_user_enrolled = user_detail_data_obj.is_enrolled;
        const all_plans_resp = await all_plans_service();
        const all_plans_array = all_plans_resp.data.data;

        //  checking is user enrooled, if yes then show only higher level plan, if not then show all
        if (is_user_enrolled == true) {
          const { enrolled_plan } = user_detail_data_obj;
          const enrolled_plan_price = enrolled_plan?.price;
          //  filter all higher plans
          const upgrade_plan = all_plans_array.filter((d) => {
            if (Number(d?.price) > Number(enrolled_plan_price)) {
              return d;
            }
          });
          if (Array.isArray(upgrade_plan) == true) {
            // if no upgrade_plan mean noting to upgrade
            if (upgrade_plan.length == 0) {
              
              setAll_plans({
                data_arr: upgrade_plan,
                render: false,
                msg: "not avaliable any higher plan to upgrade",
              });
            } else {
              // if all upgrade_plan to be avaliable to upgrade
              setAll_plans({
                data_arr: upgrade_plan,
                render: true,
                msg: "all aviliable plan to upgragrde/buy",
              });
            }
          } else {
            // since array is not loaded, may be some problem , but show not loaded data, to user, dont show error ,
            setAll_plans({
              data_arr: upgrade_plan,
              render: false,
              msg: "data not loaded",
            });
          }
        } else {
          // since user is not enrolled so show all plans
          if (Array.isArray(all_plans_array) == true) {
            setAll_plans({ data_arr: all_plans_array, render: true, msg: "" });
          } else {
            // since array is not loaded, may be some problem , but show not loaded data, to user, dont show error ,
            setAll_plans({
              data_arr: all_plans_array,
              render: false,
              msg: "data not loaded",
            });
          }
        }
      }
    };
    handler();
  }, [user_detail_data_obj]);

  return (
    <>
      <div className="card">
        <div className="card-body p-3">
          <h4>Manual Plan Purchase</h4>
          {is_data_load ? (
            <div className="basic-form">
              <form>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Plans</label>
                  <div className="col-sm-9">
                    <select
                      ref={selected_plan_id_ref}
                      name=""
                      className="default-select form-control wide"
                    >
                      {/* rendring aviliable plans */}
                      {all_plans.render ? (
                        all_plans.data_arr.length > 0 ? (
                          all_plans.data_arr.map((d) => {
                            return (
                              <option value={d._id} key={d._id}>
                                {d.plan_name} {`(price-${d.price})`}
                              </option>
                            );
                          })
                        ) : (
                          "no"
                        )
                      ) : (
                        <option>{all_plans.msg}</option>
                      )}
                      {}
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Discount</label>
                  <div className="col-sm-9">
                    <input
                      ref={admin_discount_amount_ref}
                      type="number"
                      className="form-control"
                      placeholder="Admin discount amount"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-2 w-100 text-center">
                    <div>
                      {all_plans.render == false ? (
                        <span className="text-danger">{all_plans.msg}</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    <div>
                    <Msg_display status={data_state.status} msg={data_state.msg}/>
                    </div>
                    <br />
                    <div>
                      {all_plans.render == true ? (
                        <>
                          <button
                            onClick={calculation_btn_handler}
                            type="submit"
                            className="btn btn-primary w-50 "
                          >
                            next
                          </button>
                          {show_modal && (
                            <Modal
                              show_modal={show_modal}
                              setShow_modal={setShow_modal}
                            >
                              <div className="row">
                                <div className="col-xl-12">
                                  <div className="card">
                                    <div className="card-header">
                                      Calculation
                                    </div>
                                    <div className="card-body">
                                      <div className="table-responsive active-projects style-1">
                                        <table className="table">
                                          <tbody>
                                            {/* {console.log(calculation_data.data_arr)} */}
                                          <Table2row  data_arr={calculation_data.data_arr} is_render={true}/>
                                           
                                          </tbody>
                                        </table>
                                        <div>
                                          {" "}
                                          <span>
                                            note: if calculation is ok then only
                                            purchase
                                          </span>
                                        </div>
                                         <br />
                                         <div>
                                         <Msg_display status={calculation_data.is_loaded} msg={calculation_data.msg}/>
                                        <Msg_display status={data_state.status} msg={""}/>
                                         </div>
                                        <div>
                                        {calculation_data.is_loaded == true && (
                                          <button
                                            onClick={submit_handler}
                                            className="btn btn-primary"
                                          >
                                            Purchase
                                          </button>
                                        )}
                                        {calculation_data.is_loaded ==
                                          false && (
                                          <button
                                            disabled={true}
                                            className="btn btn-primary"
                                          >
                                            Purchase
                                          </button>
                                        )}

                                        </div>

                                       
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </>
                      ) : (
                        <button
                          disabled
                          // onClick={save_handler}
                          type="submit"
                          className="btn btn-primary w-50 "
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-2 w-100"></div>
                </div>
              </form>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

const User_details_component = ({ user_details_data_state }) => {
  const [data_state, setData_state] = useState({
    is_loaded: false,
    render: true,
    data_arr: false,
    mag: "",
  });

  useEffect(() => {
    if (user_details_data_state.is_loaded == true) {
      const data_arr = [
        { is_render:true,key: "Name", value: user_details_data_state.data_obj.name },
        { is_render:true,key: "Email:", value: user_details_data_state.data_obj.email },
        { is_render:true,key: "Mobile No.", value: user_details_data_state.data_obj.mobile },
        { is_render:true,key: "State", value: user_details_data_state.data_obj.state },
        { is_render:true,key: "zipcode", value: user_details_data_state.data_obj.zipcode },
        {
          is_render:true,key: "User refrral Code",
          value: user_details_data_state.data_obj.user_referral_code,
        },
        {
          is_render:true, key: "Referrd By",
          value: user_details_data_state.data_obj.is_referral_exist
            ? user_details_data_state?.referral_by_user?.user_referral_code
            : "Not available",
        },
      ];
      setData_state({
        is_loaded: true,
        render: true,
        data_arr: data_arr,
        mag: "data not loaded",
      });
    }
  }, [user_details_data_state]);
  return (
    <div className="card">
      <div className="card-header">User Details</div>
      <div className="card-body p-2">
        {/* <div className="tbl-caption"></div> */}
        {data_state.is_loaded == true ? (<>
        
           <Table2row is_render={true} data_arr={data_state.data_arr}/>
  
           </>
            
        ) : (
          <div className="">data not loaded</div>
        )}
      </div>
    </div>
  );
};
const Purchase_details_component = ({ user_details_data_state }) => {
  const user_detail_data_obj = user_details_data_state.data_obj;
  const is_data_load = user_details_data_state.is_loaded;
  const [data_state, setData_state] = useState({
    is_loaded: false,
    render: true,
    data_arr: false,
    mag: "",
  });

  useEffect(() => {
    if (user_details_data_state?.is_loaded == true) {
      if (user_detail_data_obj?.is_enrolled == true) {
        // if user is enrolled

        // it purchase_details_data will give purchase details
        const purchase_details_data =
          user_detail_data_obj?.payments_details_junction?.purchase_details;
        const transaction_id =
          user_details_data_state?.payments_details_junction?.transaction;
        const data_arr = [
          {
            is_render: true,
            key: "Plan Name",
            value: user_detail_data_obj?.enrolled_plan?.plan_name,
          },

          {
            is_render: true,
            key: "Original plan price",
            value: purchase_details_data?.original_plan_price,
          },
          // discounts
          {
            is_render: true,
            key: "Discount",
            value:
              purchase_details_data?.is_discount == true
                ? `${purchase_details_data?.discount_calc_details?.discount_value}(${purchase_details_data?.discount_calc_details?.discount_percentage}%)`
                : "not avaliable",
          },
          {
            is_render: true,
            key: "Coupon Discount",
            value:
              purchase_details_data?.is_coupon == true
                ? `${purchase_details_data?.discount_calc_details?.coupon_discount_value}(${purchase_details_data?.discount_calc_details?.coupon_discount_percentage}%)`
                : "not avaliable",
          },

          // {render:true,key: "Discount", value: purchase_details_data.original_plan_price},
          // {render:true,key: "Coupon Discount", value: purchase_details_data.original_plan_price },

          {
            is_render: true,
            key: "Final Plan Price",
            value: purchase_details_data?.all_calculations?.final_plan_price,
          },
          {
            is_render: true,
            key: "Final Plan Price(without gst)",
            value:
              purchase_details_data?.all_calculations?.plan_price_without_gst,
          },
          {
            is_render: true,
            key: "GST",
            value: purchase_details_data.all_calculations?.gst_percentage,
          },
          {
            is_render: true,
            key: "GST(in amount)",
            value: purchase_details_data?.all_calculations?.gst_amount,
          },
          {
            is_render: true,
            key: "CSV",
            value:
              purchase_details_data?.all_calculations
                ?.commission_sales_value_csv,
          },
          {
            is_render: true,
            key: "Razorpay Charges Amount",
            value:
              purchase_details_data?.all_calculations?.razorpay_charges_amount,
          },
          {
            is_render: true,
            key: "Plan Commision Percentage",
            value: purchase_details_data?.all_calculations?.commision_percentage,
          },
          {
            is_render: true,
            key: "Referral Commission Amount",
            value: purchase_details_data?.all_calculations?.commission_amount,
          },
          {
            is_render: true,
            key: "TDS Percentage",
            value: purchase_details_data?.all_calculations?.TDS_percentage,
          },
          {
            is_render: true,
            key: "TDS(in amount)",
            value: purchase_details_data?.all_calculations?.TDS_amount,
          },
          {
            is_render: true,
            key: "Referral Commision Amount(after TDS)",
            value:
              purchase_details_data?.all_calculations
                ?.commision_amount_after_TDS,
          },
        ];
        setData_state({
          is_loaded: true,
          render: true,
          data_arr: data_arr,
          mag: "data not loaded",
        });
      }
    }
  }, [user_details_data_state]);
  return (
    <div className="card">
      <div className="card-header">Plan Purchase Details</div>
      <div className="card-body p-2">
        <div className="tbl-caption"></div>
        {is_data_load == true ? (
          <div className="table-responsive active-projects style-1">
            <table className="table">
              <tbody><Table2row data_arr={data_state.data_arr} is_render={true} /> </tbody>
            </table>
          </div>
        ) : (
          <div className="">data not loaded</div>
        )}
      </div>
    </div>
  );
};
const User_status_info_Component = ({ user_details_data_state }) => {
  const [data_state, setData_state] = useState({
    is_loaded: false,
    render: true,
    data_obj: false,
    mag: "",
  });

  useEffect(() => {}, [user_details_data_state]);

  return (
    <div className="card ">
      <div className="card-header">User Status</div>
      <div className="card-body " style={{ minHeight: "100px" }}>
        <div className="row">
          {/* <div className="col-xl-3 d-flex justify-content-center">
      <div>
        <h5>User Status</h5>
        <div className="d-flex w-100 justify-content-center">
          {user_api_data.active ? (
            <button className="btn btn-success">
              Active
            </button>
          ) : (
            <button className="btn btn-danger">
              Deactive
            </button>
          )}
        </div>
      </div>
    </div> */}
          <div className="col-xl-3 d-flex justify-content-center">
            <div>
              <h5>KYC Status</h5>
              <div className="d-flex w-100 justify-content-center">
                {user_details_data_state?.data_obj?.kyc_status ? (
                  <button className="btn btn-success">Verified</button>
                ) : (
                  <button className="btn btn-danger">Not Verified</button>
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-xl-3 d-flex justify-content-center">
      <div>
        <h5>User Status</h5>
        <div className="d-flex w-100 justify-content-center">
          {user_api_data.verified ? (
            <button className="btn btn-success">
              Verified
            </button>
          ) : (
            <button className="btn btn-danger">
             Not Verified
            </button>
          )}
        </div>
      </div>
    </div> */}
          <div className="col-xl-3 d-flex justify-content-center">
            <div>
              <h5>Plan Enrollment</h5>
              <div className="d-flex w-100 justify-content-center">
                {user_details_data_state?.data_obj?.is_enrolled ? (
                  <button className="btn btn-success">Enrolled</button>
                ) : (
                  <button className="btn btn-danger">Not enrolled</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Kyc_update_Component=({selecetd_user_id,update_state_function,user_details_data_state})=>{
  const is_data_load = user_details_data_state.is_loaded;
  const [data_state,setData_state]=useState({
    status:"",msg:""
  })
  const kyc_status = useRef();
  const kyc_status_messgae = useRef()

  const save_handler = async (e) => {
    e.preventDefault();
    try {
      setData_state({status:null,msg:""})
      const kyc_status_value = kyc_status.current.value;
      const kyc_status_messgae_value = kyc_status_messgae.current.value;
      const kyc_resp = await kyc_action_service(
        selecetd_user_id,
        kyc_status_value,
        kyc_status_messgae_value,
      );
      if (kyc_resp.status == 200) {
        // console.log(kyc_resp)
        update_state_function()
        setData_state({status:true,msg:"Updated successfully"})
        // alert("updated sucessfully");
      }
      else{
        setData_state({status:false,msg:"try again"})
      }
    } catch (error) {}
  };


return <div className="card">
<div className="card-body p-3">
  <h4>Update User Kyc</h4>

  {is_data_load==true? <div className="basic-form">
    <form>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">
          KYC status
        </label>
        <div className="col-sm-9">
          <select
            ref={kyc_status}
            name=""
            selecetd_user_id=""
            className="default-select form-control wide"
          >
            <option value="verified">Verified</option>
            <option value="not_verified">Not Verified</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-3 col-form-label">Message</label>
        <div className="col-sm-9">
          <input
            ref={kyc_status_messgae}
            type="text"
            className="form-control"
            placeholder="messgae/comment"
          />
        </div>
      </div>
      <div className="row">
      <div className="mb-3 col-md-2 w-100 text-center">
          <Msg_display status={data_state.status} msg={data_state.msg}/>
      
        </div>
        <div className="mb-3 col-md-2 w-100 text-center">
          <button
            onClick={save_handler}
            type="submit"
            className="btn btn-primary w-50 "
          >
            Save
          </button>
        </div>
      </div>
      <div className="row">
        <div className="mb-3 col-md-2 w-100"></div>
      </div>
    </form>
  </div>
  :
   <div></div>
  }
 
</div>
</div>
}
function User_kyc_details(props) {

 
  const [apidata, setapidata] = useState("");
  const [data, setdata] = useState([]);
  const id = localStorage.getItem("selected_user_id");

  useEffect(() => {
    // setdatarender(data)
    const handler = async () => {
      const resp = await user_details(id);

      setapidata(resp.data);
      // console.log(resp,"data");
      const user_detail = resp?.data?.user_detail;
      // console.log(user_detail, "user_detail");
      if (user_detail == undefined) {
        alert("no data");
      } 
      else if (user_detail.kyc_upload == true) {
        // console.log(user_detail.kyc_upload);
        // console.log(user_detail, "user_detail2");

      setdata([
          { key: "Name", value: user_detail.kyc.name },
          { key: "Adhar Card No.", value: user_detail.kyc.adhar_card },
          { key: "PAN Card", value: user_detail.kyc.pan_card },
          {
            key: "Bank Name",
            value: user_detail.kyc.bank_account_details.bank_name,
          },
          {
            key: "Bank Account No",
            value: user_detail.kyc.bank_account_details.account_number,
          },
          {
            key: "IFSC code",
            value: user_detail.kyc.bank_account_details.ifsc_code,
          },
        ]);
      }
    };
    handler();
  }, []);

  const data_render = (data_arr) => {
    // console.log(data_arr);
    return data_arr.map((d, n) => {
      const code = (
        <tr key={(n = "key")}>
          <td>
            <div style={{ textAlign: "left" }}>{d?.key}</div>
          </td>
          <td>
            <div style={{ textAlign: "left" }}>{d?.value}</div>
          </td>
        </tr>
      );
      return code;
    });
  };
  return ( <>
   <div className="card">
    <div className="card-header">Kyc Details</div>
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1">
                  <div className="tbl-caption"></div>
                  <table className="table">
                    <thead></thead>
                    <tbody>{data_render(data)}</tbody>
                  </table>
                </div>
              </div>
            </div>
         
        
    </>
  );
}





function User_details(props) {
  const [update_state,setUpdate_state]=useState(0)

  const [user_api_data, setUser_api_data] = useState({
    is_loaded: null,
    render: true,
    data_obj: {},
    msg: "",
  });

  const [data, setdata] = useState([]);
  const selecetd_user_id = localStorage.getItem("selected_user_id");
  
  // when we call it it just
  const update_state_function=()=>{
    setUpdate_state(e=>e+1)
  }

  useEffect(() => {
    // setdatarender(data)

    const handler = async () => {
      setUser_api_data({
        is_loaded: null,
        render: true,
        data_obj: {},
        msg: "",
      });



      const resp = await user_details(selecetd_user_id);
      const user_detail = resp?.data?.user_detail;
      console.log(resp);
      if (user_detail != undefined) {
        setTimeout(()=>{
        setUser_api_data({
            is_loaded: true,
            render: true,
            data_obj: user_detail,
            msg: "",
          });

        },5000)
      }
      else{
        
        setUser_api_data({
          is_loaded: false,
          render: true,
          data_obj: {},
          msg: "",
        });
        // alert("no data");
        return <h3>data not loaded</h3>;
      }
      
    };
    handler();
  }, [update_state]);
 

  return (
    <>
    
      <Page_titles name={props.name} />
      <div className="container-fluid">
    
      <div className="row">
      {/* user info */}
     
      <div className="col-xl-12">
        <Show_hide btn_name={"User Referrals"}>
        
        <PreLoader status={user_api_data.is_loaded} msg="not loaded">
        <User_referrals_list_Component
              selecetd_user_id={selecetd_user_id}
              update_state_function={update_state_function}
              update_state={update_state}
            />
        </PreLoader>
     
     </Show_hide>  
      </div>

    

      <div className="col-xl-12">
        <br />
        <br />
        <div className="card p-0">
          <div className="card-header">User Info</div>
          <PreLoader status={user_api_data.is_loaded} msg="not loaded">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-6">
              
              <User_details_component
                  user_details_data_state={user_api_data}
                />
             
                
              </div>
              <div className="col-xl-6">

                <Purchase_details_component
                  user_details_data_state={user_api_data}
                />
              </div>
              <div className="col-xl-6">
                <User_status_info_Component
                  user_details_data_state={user_api_data}
                />
              </div>
              <div className="col-xl-6">
              <User_kyc_details/>
              </div>
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">Uploaded items</div>
                  <div className="card-body p-0"></div>
                </div>
              </div>
            </div>
          </div>

            </PreLoader>
        
        </div>
      </div>
      {/* user kyc */}
      <br />
      <br /><br />
     
     
    </div>
    <br />
    <br/>
    <div className="row">


<div className="col-xl-6">
  <Kyc_update_Component selecetd_user_id={selecetd_user_id}  user_details_data_state={user_api_data} update_state_function={update_state_function}/>
</div>
<div className="col-xl-6">
  <Plan_purchase_manual_by_admin_component
    selected_user_id={selecetd_user_id}
    user_details_data_state={user_api_data}
    update_state_function={update_state_function}
  />
</div>

</div>
        
      </div>
    </>
  );
}

export default User_details;
