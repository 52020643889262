import React, { useEffect, useRef, useState } from "react";
import Page_titles from "../../components/Page_titles";
import { useSelector } from "react-redux";
import { get_all_commission_transaction_service } from "../../services/user";
import Msg_display from "../../components/Msg_display";
import Show_hide from "../../components/Show_hide";
import Modal from "../../components/Modal";
import { Navigate, useNavigate } from "react-router-dom";
import { all_payments_transaction_service } from "../../services/data";

const compareDates = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();
  // console.log(date1,date2)
  if (date1 > date2) {
    return true;
  } else {
    return false;
  }
};



const Data_render = ({ data, n }) => {
  const navigation = useNavigate();
  const [modal_for_view, setModal_for_view] = useState(false);

  // Modal for more details
  // console.log(data)
  const slno=n
  const date = data.createdAt + "";
  const show_date = date.slice(0, 10);
//   user releted
  const user_email = data?.user?.email;
  const user_mobile = data?.user?.mobile;
  const enrolled_plan=data?.plan?.plan_name

  const user_id = data?.user?._id;
//   prchase amount releted
  const orignal_price=data?.purchase_details?.original_plan_price;
  const final_price=data?.purchase_details?.final_plan_price;
  const is_upgraded= data?.purchase_details?.is_upgraded

//
const is_referral_purchase=data?.is_reffral_exist

// transaction
  const transaction_id=data?.transaction?._id
  const payment_method=data?.transaction?.payment_method;
  const RZP_payments_id=data?.transaction?.payment_id;
  const RZP_order_id=data?.transaction?.payment_id;
  const status=data?.transaction?.status;
  const Payments_id=data?._id
  // const view_handler=()=>{
  //     console.log(data)
  //     setModal_for_view(!modal_for_view)
  // }
  const view_handler = (event) => {
    localStorage.setItem("selected_user_id", event.target.id);
    navigation("/user_details");
  };
  const save_handler = async (e) => {
    e.preventDefault();
    // alert("ok");
    localStorage.setItem('selected_payment_id',e.target.id)
    navigation('/payment-details')
 
  };

  return (
    <tr key={data._id}>
      <td>
        <span>{slno}</span>
      </td>
      <td>
        <span>{orignal_price}</span>
      </td>
      <td>
        <span>{final_price}</span>
      </td>
      <td>{enrolled_plan}</td>

      <td>
        {status ? (
          <span className="badge badge-success light border-0">successful</span>
        ) : (
          <span className="badge badge-danger light border-0">not validated</span>
        )}
      </td>
      <td>
        <span>{show_date}</span>
      </td>
      <td>
      {payment_method}
      </td>
      
      <td >
       {RZP_payments_id?RZP_payments_id:"not avaliable"}
    
      </td>
      <td>
      <td>
        {is_upgraded ? (
          <span className="badge badge-success light border-0">yes</span>
        ) : (
          <span className="badge badge-danger light border-0">no</span>
        )}
      </td>
      </td>
      <td>
        <span>{user_email}</span>
      </td>
      <td>
        <span>{user_mobile}</span>
      </td>

      <td>
        <button id={user_id} onClick={view_handler} className="btn btn-primary">
          View
        </button>
      </td>
    
      
      <td>
        <button
          onClick={save_handler}
          id={data._id}
          className="btn btn-primary"
        >
          More Details
        </button>
      </td>
    </tr>
  );
};
const App = (props) => {
  const [data_state, setdata_state] = useState({
    is_loaded: null,
    msg: "",
    is_render: false,
    data_arr: [],
  });

  useEffect(() => {
    const api_handler = async (e) => {
      // e.preventDefault()
      const resp = await all_payments_transaction_service();
      if (resp.status == 200) {
        const data_arr = resp.data.data;
        // console.log(resp);
        if (Array.isArray(data_arr) == true) {
            const reverse=data_arr.reverse();
          setdata_state({
            is_loaded: true,
            msg: "",
            is_render: true,
            data_arr: reverse,
          });
        }
        else{
          setdata_state({
            is_loaded: false,
            msg: "data not loaded",
            is_render: true,
            data_arr: [],
          });
        }
      }
    };
    api_handler();
  }, []);

  return (
    <>
      <Page_titles name={props.name} />

      <div className="container-fluid">
        <div className="row ">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1">
                  <table id="empoloyees-tblwrapper " className="table">
                    <thead>
                      <tr>
                        <th>SL No.</th>
                        <th>Orignal P.</th>
                        <th>Purchase P.</th>
                        <th>Enrolled Plan</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Payments Method</th>
                        <th>Payments IDs</th>
                        
                        <th>Upgraded</th>

                       
                        <th>User email</th>
                        <th>Mobile No.</th>
                        <th>User Details</th>
                        <th>More Detalis</th>


                        
                      </tr>
                    </thead>
                    {data_state.is_loaded == true ? (
                      <tbody>
                        {data_state.data_arr.map((data, n) => {
                          return (
                            <Data_render
                              data={data}
                              n={data_state.data_arr.length - n}
                            />
                          );
                        })}
                      </tbody>
                    ) : (
                      <>
                        <br />
                        <br />
                        <Msg_display
                          status={data_state.is_loaded}
                          msg={data_state.msg}
                        />
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
            {/* <button onClick={api_handler} className="btn btn-primary">click</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
