
import axios from "axios";
const token=localStorage.getItem("token")
export const add_coupon_code_service=async(...args)=>{
   
    try {
        const resp = await axios.post(process.env.REACT_APP_API + "/master_user"+"/add_coupon",{
         payload:{
                active_status:args[0].active_value,
                name:args[0].name_value,
                coupon_code:args[0].coupon_code_value,
                details:args[0].details_value,
                expire_date:args[0].expire_date_value,
                discount_percentage:args[0].discount_percentage_value

            }
        },
        { headers: {"Authorization" : `${token}`} });
        
       
       return resp;
      } catch (error) {
        console.log(error)
        return error;
      } 
  }
export const all_coupons_service=async(...args)=>{

    try {
        const resp = await axios.get(process.env.REACT_APP_API + "/master_user"+"/all_coupons",
        { headers: {"Authorization" : `${token}`} });
        
       return resp;
      } catch (error) {
        console.log(error)
        return error;
      } 
  }
export const delete_coupon_service=async(id)=>{
 
    try {
        const resp = await axios.post(process.env.REACT_APP_API + "/master_user"+"/delete_coupon",{
            id:id
        },
        { headers: {"Authorization" : `${token}`},
     });
        
       
       return resp;
      } catch (error) {
        console.log(error)
        return error;
      } 
  }


    
    
    
    
     
