


import axios from "axios";

// "email":"krishna@gmail.com",
//  "password":"123456",
//
const token=localStorage.getItem("token");
;

export const all_payments_transaction_service=async()=>{
  
    try {
          const resp = await axios
          .get(process.env.REACT_APP_API +"/master_user"+ "/data/all_payments_transaction",
          { headers: {"Authorization" : `${token}`} });
          
         //  console.log(resp);
         return resp;
        } catch (error) {
          console.log(error)
          return {
            msg: "internal error",
          };
        }
    }
    // one payment_transaction_details
export const payment_transaction_details=async(id)=>{
  
      try {
            const resp = await axios
            .post(process.env.REACT_APP_API +"/master_user"+ "/data/payment_transaction_details",{
              id:id
            },
            { headers: {"Authorization" : `${token}`} });
            
         
           return resp;
          } catch (error) {
            console.log(error)
            return {
              msg: "internal error",
            };
          }
      }