import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  isLoading: false,
  error: null,
  data: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    check: (state, action) => {

      state.data = action.payload;
    },
    login: (state, action) => {
   
      state.isLogin =action.payload;
    },
    isloading: (state, action) => {
      // state.isLogin = action.payload.login_status;
    },
    logout: (state, action) => {
      state.isLogin = false;
    },
  },
  // Optionally include extraReducers
});
export const { login, logout, check } = authSlice.actions;
