import axios from "axios";

// "email":"krishna@gmail.com",
//  "password":"123456",
//
const token=localStorage.getItem("token")

export const kyc_action_service=async(user_id,kyc_status,message)=>{
    try {
        const resp = await axios.post(process.env.REACT_APP_API +"/master_user"+ "/kyc_verification",{
          user_id:user_id,
          kyc_status:kyc_status,
          kyc_status_message:message
        },
        { headers: {"Authorization" : `${token}`} });
        
     
       return resp;
      } catch (error) {
        console.log(error)
        return {
          msg: "internal error",
        };
      }
  }