import React, { useState } from "react";
import style from "../App.css";

import { Routes, Route, Switch, useParams } from "react-router-dom";



// user pages
import User_dashboard from "../pages/User_pages/User_dashboard"
import All_users from "../pages/User_pages/all_users"
import User_kyc from "../pages/User_pages/user_kyc"
import Coupan_code from "../pages/User_pages/coupon"

import Meeting from "../pages/User_pages/meeting"
import Plans from "../pages/User_pages/Plans"
// public pages


// nav
import TopNav from "../components/TopNav";
import Sidebar from "../components/Sidebar";
import User_kyc_details from "../pages/User_pages/user_kyc_details";
import User_details from "../pages/User_pages/user_details";
import Commission_payments from "../pages/User_pages/commission_payments";
import Payments_transaction from "../pages/User_pages/payments";
import Payment_details from "../pages/User_pages/payment_details";

// import Affiliate_panel from "./panels/Affiliate_panel";

function Page404(params) {
  const link = useParams();

  return (
    <div className="d-flex column justify-content-center ">
      
      <div>
        <h2>{link["*"]}</h2>
        
        <h5>404 not found</h5>
      </div>
    </div>
  );
}



function App() {
  const [sideBar_show, setsideBar_show] = useState(false);
 

  return (
    <div id="main-wrapper" className={`show ${sideBar_show?"menu-toggle":""} `}>
      <TopNav  setsideBar_show={setsideBar_show} sideBar_show={sideBar_show} />
      <Sidebar/>
      <div className="content-body">
        <Routes>
          <Route path="" element={<All_users name={"Users"} />} />
          <Route path="/dashboard" element={<All_users name={"Users"} />} />
          <Route path="/all_users" element={<All_users name={"Users"} />} />
          <Route path="/users_kyc" element={<User_kyc name={"Users Kyc"} />} />
          <Route path="/kyc_details" element={<User_kyc_details name={"Kyc Details "} />} />
          <Route path="/plans" element={<Plans name={"Plans"} />} />
          <Route path="/meeting" element={<Meeting name={"Meeting"} />} />
          <Route path="/coupon" element={<Coupan_code name={"Coupons"} />} />
          <Route path="/user_details" element={< User_details name={"User Details"} />} />
          <Route path="/commission_payments" element={< Commission_payments name={"Commssion payments"} />} />
          <Route path="/payments" element={< Payments_transaction name={"Payments Transaction"} />} />
          <Route path="/payment-details" element={< Payment_details name={"Payments Details"} />} />
          <Route path="*" element={<Page404 />} />
          {/* <Route path="*" element={<Page404 />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
