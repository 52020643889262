import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../store/authSlice";
import { useDispatch } from "react-redux";
import { nanoid } from "nanoid";

function Sidebar() {
  const dispatch = useDispatch();
  const navigation=useNavigate()
  const logout_handler = () => {
    dispatch(logout(false));
    // dispatch(login(false))
    localStorage.removeItem("token")
    navigation("/")
  };
  const temp_logo = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
<path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
  const menulist = [
    // { img_logo_svg: temp_logo, name: "Dashboard", link_route: "/dashboard" },
   
    {
      img_logo_svg: temp_logo,
      name: "Users",
      link_route: "/all_users",
    },
    {
      img_logo_svg: temp_logo,
      name: "User Kyc",
      link_route: "/users_kyc",
    },
    {
      img_logo_svg: temp_logo,
      name: "Plans",
      link_route: "/plans",
    },
    
    {
      img_logo_svg: temp_logo,
      name: "Payments Transaction",
      link_route: "/payments",
    },
    {
      img_logo_svg: temp_logo,
      name: "Commission Payments",
      link_route: "/commission_payments",
    },
    {
      img_logo_svg: temp_logo,
      name: "Meeting",
      link_route: "/meeting",
    },
    {
      img_logo_svg: temp_logo,
      name: "Coupon",
      link_route: "/coupon",
    },
  ];
  return (
    <>
      {/***********************************
      Sidebar start
  ************************************/}
      <div className="deznav">
        <div className="deznav-scroll" >
          <ul className="metismenu"   id="menu">
            {/* <li className="menu-title">YOUR COMPANY</li> */}

            {/* <li>
              <a href="employee.html" className="" aria-expanded="false">
                <div className="menu-icon">
                  <svg
                    width={22}
                    height={22}
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.986 14.0673C7.4407 14.0673 4.41309 14.6034 4.41309 16.7501C4.41309 18.8969 7.4215 19.4521 10.986 19.4521C14.5313 19.4521 17.5581 18.9152 17.5581 16.7693C17.5581 14.6234 14.5505 14.0673 10.986 14.0673Z"
                      stroke="#888888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.986 11.0054C13.3126 11.0054 15.1983 9.11881 15.1983 6.79223C15.1983 4.46564 13.3126 2.57993 10.986 2.57993C8.65944 2.57993 6.77285 4.46564 6.77285 6.79223C6.76499 9.11096 8.63849 10.9975 10.9563 11.0054H10.986Z"
                      stroke="#888888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <span className="nav-text">Employees</span>
              </a>
            </li> */}

            {menulist.map((d) => {
              return (
                <li  key={nanoid()}>
                  <Link to={d.link_route} className="" aria-expanded="false">
                    <div  className="menu-icon">{d.img_logo_svg}</div>
                    <span className="nav-text">{d.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div onClick={logout_handler} className="help-desk">
            <a  className="btn btn-primary">
              logout
            </a>
          </div>
        </div>
      </div>
      {/***********************************
      Sidebar end
  ************************************/}
    </>
  );
}

export default Sidebar;
