


// when data load, till not data recieved , it will show
import React, { useState } from "react";
import LoaderComp from "./Loader";

function PreLoader(props) {
  const status=props.status
const msg=props.msg

  return (
    <>
   
      {status == false && <span className="text text-danger">{msg}</span>}
      {status == null && (
        <span>
          <div
            className="w-100 d-flex justify-content-center  align-items-center"
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              top: "0px",
              bottom: "0px",
              textAlign: "center",
              zIndex: "10",
            }}
          >
            <div
              className="d-flex justify-content-center btn btn-primary light align-items-center text-center"
              style={{ height: "50px", width: "50px", borderRadius: "50%" }}
            >
              <LoaderComp width={"30px"} height={"30px"} />
            </div>
          </div>
        </span>
      )}
      {status == true && <>
      {props.children}
      </>}
    </>
  );
}

export default PreLoader;
